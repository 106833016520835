<template>
  <v-card class="triangle-card">
    <v-row>
      <v-col cols="8">
        <v-card-title class="page-title text-no-wrap pt-2">
          Congratulations {{ userData.first_name || userData.username }}!
        </v-card-title>
        <v-card-subtitle class="text-no-wrap">
          You did it! See your results bellow!
        </v-card-subtitle>
        <v-card-text class="d-flex align-center pb-2">
          <div>
            <p class="mb-0">
              Number of tries: <span class="text-2xl font-weight-semibold warning--text mb-0">{{$store.state['memory'].numberOfTries}}</span>
            </p>
            <p class="" v-if="useTimerLocal">
              Time: <span class="text-2xl font-weight-semibold warning--text">{{$store.state['memory'].timeElapsed}}</span>
            </p>
            <v-btn
              color="primary"
              class="mt-5"
            >
              Play again
            </v-btn>
          </div>
        </v-card-text>
      </v-col>
      <v-col cols="4">
        <v-img
          contain
          width="166px"
          height="190px"
          :src="require(`@/assets/images/misc/triangle-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
          class="triangle-bg"
        ></v-img>
        <v-img
          contain
          height="108"
          max-width="83"
          class="gamification-trophy"
          src="@/assets/images/misc/trophy.png"
        ></v-img>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import store from '@/store'
import { ref, computed } from '@vue/composition-api'

export default {
  props: {
    useTimer: Boolean
  },
  setup(props) {

    const userData = JSON.parse(localStorage.getItem('userData'))

    const useTimerLocal = computed(() => props.useTimer)

    return {
      userData,
      useTimerLocal
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';
.triangle-card {
  position: relative;
  max-width: 600px;
  margin: 0 auto;
  .triangle-bg {
    position: absolute;
    bottom: 0;
    @include ltr() {
      right: 0;
    }
    @include rtl() {
      left: 0;
    }
  }
  .gamification-trophy {
    position: absolute;
    bottom: 10%;
    @include ltr() {
      right: 8%;
    }
    @include rtl() {
      left: 8%;
    }
  }
}
.gamification-tree-4,
.gamification-john-pose-2,
.gamification-tree {
  position: absolute;
}
.gamification-tree {
  top: 10%;
  @include ltr() {
    right: 0;
  }
  @include rtl() {
    left: 0;
  }
}
.gamification-john-pose-2 {
  bottom: 0;
  @include ltr() {
    right: 15%;
  }
  @include rtl() {
    left: 15%;
  }
}
.gamification-tree-4 {
  bottom: 0;
  @include ltr() {
    right: 30%;
  }
  @include rtl() {
    left: 35%;
  }
}

@media (max-width: 600px) {
  .gamification-tree,
  .gamification-tree-4 {
    display: none;
  }
  .gamification-john-pose-2 {
    @include ltr() {
      right: 5%;
    }
    @include rtl() {
      left: 5%;
    }
  }
}

@media (max-width: 500px) {
  .gamification-john-pose-2 {
    max-width: 70px;
  }
}

@media (max-width: 400px) {
  .page-title {
    font-size: 1.2rem !important;
  }
}
</style>
