<template lang="html">
  <div class="memory-game-wrapper">
    <v-row>
      <v-col v-show="!gameStarted" cols="12" md="6" class="ml-auto mr-auto mt-5">
        <v-card>
          <v-card-title class="justify-center">
            Choose your game
          </v-card-title>
          <v-card-text class="">
            <p>Number of cards</p>
            <v-btn-toggle
              rounded
              color="warning"
              v-model="gameMode"
            >
              <v-btn v-for="mode in gameModes" :value="mode.id">
                {{ mode.name }}
              </v-btn>
            </v-btn-toggle>
            <v-switch
              v-model="useTimer"
              label="Use timer"
              color="warning"
            ></v-switch>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              @click="tileCards"
            >
              Start game
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
    v-model="gameStarted"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    eager
    >
      <v-card tile>
        <v-toolbar
          dark
          color="primary"
          class="mb-8"
        >
          <v-btn
            icon
            dark
            @click="closeGame"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
          <v-toolbar-title>Number of tries: <strong>{{ $store.state['memory'].numberOfTries }}</strong> </span>Matched: <strong>{{ $store.state['memory'].matchedNumbers.length }}</strong></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-title v-if="useTimer">
            <Stopwatch ref="stoparca"></Stopwatch>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              color="error"
              class="my-auto"
              tile
              small
              @click="resetGame"
            >
              Reset game
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <success-card v-show="isGameFinished" :use-timer="useTimer"></success-card>

        <div v-show="!isGameFinished" class="d-flex justify-center flex-wrap">
          <template v-for="(card,index) in cards">
            <FlipCard
              :number="card"
              :index="index"
              :height="boardSettings.card_height"
              :width="boardSettings.card_width"
            ></FlipCard>
            <div class="break-row" v-if="(index+1)%boardSettings.cards_in_row == 0"></div>
          </template>
        </div>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import FlipCard from './FlipCard.vue'
import Stopwatch from './Stopwatch.vue'
import SuccessCard from './SuccessCard.vue'
import store from '@/store'
import { mdiClose } from '@mdi/js'
import memoryStoreModule from './memoryStoreModule'
import { ref, computed, onUnmounted, watch } from '@vue/composition-api'

export default {
  components: {
    FlipCard,
    Stopwatch,
    SuccessCard
  },
  setup() {

    const MEMORY_STORE_MODULE_NAME = 'memory'

    // Register module
    if (!store.hasModule(MEMORY_STORE_MODULE_NAME)) {
      store.registerModule(MEMORY_STORE_MODULE_NAME, memoryStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MEMORY_STORE_MODULE_NAME)) store.unregisterModule(MEMORY_STORE_MODULE_NAME)
    })

    const stoparca = ref(null)
    const useTimer = ref(false)
    const gameStarted = ref(false)
    const isGameFinished = ref(false)
    const gameMode = ref("beginner")
    const boardSettings = ref({})
    const cards = ref([])
    const gameModes = [
      {
        id: "beginner",
        name: "Beginner (3x2)",
        settings: {
          num_of_cards: 6,
          num_of_rows: 3,
          cards_in_row: 2,
          card_width: 200,
          card_height: 200
        }
      },
      {
        id: "easy",
        name: "Easy (4x4)",
        settings: {
          num_of_cards: 16,
          num_of_rows: 4,
          cards_in_row: 4,
          card_width: 200,
          card_height: 200
        }
      },
      {
        id: "moderate",
        name: "Moderate (6x6)",
        settings: {
          num_of_cards: 36,
          num_of_rows: 6,
          cards_in_row: 6,
          card_width: 150,
          card_height: 100
        }
      },
      {
        id: "hard",
        name: "Hard (8x8)",
        settings: {
          num_of_cards: 64,
          num_of_rows: 8,
          cards_in_row: 8,
          card_width: 70,
          card_height: 100
        }
      }
    ]

    const tileCards = () => {
      gameStarted.value = true
      if (useTimer.value) {
          stoparca.value.start()
      }

      for (var i = 0; i < gameModes.length; i++) {
        if (gameMode.value == gameModes[i].id) {
          boardSettings.value = gameModes[i].settings
        }
      }

      for (var j = 0; j < 2; j++) {
        for (var i = 1; i <= boardSettings.value.num_of_cards/2; i++) {
            cards.value.push(i)
        }
      }

      shuffleArray(cards.value)

    }

    const shuffleArray = array => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
    }

    const resetGame = () => {
      store.commit("memory/RESET_CURRENT_MATCHED")
      store.commit("memory/SET_CURRENT_INDEX",-2)
      store.commit("memory/SET_CURRENT_NUMBER",-2)
      store.commit("memory/RESET_NR_OF_TRIES")
      cards.value.length = 0
      isGameFinished.value = false;
      if (useTimer.value) {
        stoparca.value.reset()
      }
      setTimeout(() => {
        tileCards()
        stoparca.value.start()
      },1000)
    }

    const closeGame = () => {
      if (useTimer.value) {
        stoparca.value.reset()
      }
      useTimer.value = false
      gameStarted.value = false
      cards.value.length = 0
      store.commit("memory/SET_CURRENT_INDEX",-1)
      store.commit("memory/SET_CURRENT_NUMBER",-1)
      store.commit("memory/RESET_CURRENT_MATCHED")
      store.commit("memory/RESET_NR_OF_TRIES")
      isGameFinished.value = false;
    }

    watch(() => store.state['memory'].matchedNumbers, (nums) => {
      if (store.state['memory'].matchedNumbers.length == (boardSettings.value.num_of_cards/2)) {
        if (useTimer.value) {
          stoparca.value.stop()
        }
        isGameFinished.value = true
        cards.value.length = 0
      }
    })

    return {
      stoparca,
      isGameFinished,
      useTimer,
      resetGame,
      closeGame,
      gameModes,
      gameMode,
      gameStarted,
      tileCards,
      cards,
      boardSettings,
      icons: {
        mdiClose
      }
    }

  }
}
</script>

<style lang="css" scoped>
.break-row {
  flex-basis: 100%;
  height: 0;
}
</style>
