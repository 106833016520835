export default {
  namespaced: true,
  state: {
    matchedNumbers: [],
    currentNumber: -1,
    currentIndex: -1,
    numberOfTries: 0,
    timeElapsed: 0
  },
  getters: {},
  mutations: {
    INCREASE_NR_OF_TRIES(state){
      state.numberOfTries += 1
    },
    RESET_NR_OF_TRIES(state){
      state.numberOfTries = 0
    },
    SET_CURRENT_NUMBER(state, num){
      state.currentNumber = num
    },
    SET_CURRENT_INDEX(state, index){
      state.currentIndex = index
    },
    RESET_CURRENT_MATCHED(state, num){
      state.matchedNumbers.length = 0
    },
  },
  actions: {

  },
}
