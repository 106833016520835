<template>
  <div v-bind:class="[flipped ? 'flip-container flipped': 'flip-container', flippedPermanent ? 'permanent' : '']">
    <div class="flipper">
      <div class="front" v-on:click="flipCard">
        <v-card :width="cardWidth" :height="cardHeight" class="justify-center d-flex align-center ml-3 mr-3 mb-6">
          <v-btn
            icon
            fab
            color="error"
          >
            <v-icon>
              {{ icons.mdiHelp }}
            </v-icon>
          </v-btn>
        </v-card>
      </div>
      <div class="back">
        <v-card :width="cardWidth" :height="cardHeight" class="justify-center d-flex align-center ml-3 mr-3 mb-6">
          <v-img :src="require('@/assets/images/eCommerce/'+ cardNumber +'.png')"></v-img>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, emit, computed, watch } from '@vue/composition-api'
import { mdiHelp } from '@mdi/js'
import store from '@/store'

export default {
  name: 'FlipCard',
  props: {
    number: Number,
    index: Number,
    width: Number,
    height: Number
  },
  setup(props, {emit}) {

    const flipped = ref(false)
    const flippedPermanent = ref(false)

    const cardNumber = computed (() => props.number)
    const cardIndex = computed (() => props.index)
    const cardWidth = computed (() => props.width)
    const cardHeight = computed (() => props.height)

    const flipCard = () => {
      flipped.value = true
      if (store.state['memory'].currentIndex < 0) {
        store.commit("memory/SET_CURRENT_INDEX",cardIndex.value)
        store.commit("memory/SET_CURRENT_NUMBER",cardNumber.value)
        store.commit("memory/INCREASE_NR_OF_TRIES")
      } else {
        if (cardNumber.value == store.state['memory'].currentNumber) {
          store.state['memory'].matchedNumbers.push(cardNumber.value)
        } else {

        }
        store.commit("memory/SET_CURRENT_INDEX",-1)
        store.commit("memory/SET_CURRENT_NUMBER",-1)
      }
    }

    watch(() => store.state['memory'].currentIndex, (index, prevIndex) => {
      if (store.state['memory'].currentIndex == -2) {
        flipped.value = false
      } else if (store.state['memory'].currentIndex < 0 && !store.state['memory'].matchedNumbers.includes(cardNumber.value)) {
        setTimeout(() => {
          flipped.value = false
        },1000)
      }
    })

    watch(() => store.state['memory'].matchedNumbers, (nums) => {
      if (store.state['memory'].matchedNumbers.includes(cardNumber.value)) {
        setTimeout(() => {
          flippedPermanent.value = true
        },500)
      } else {
        flippedPermanent.value = false
      }
    })

    return {
      flipped,
      flippedPermanent,
      flipCard,
      cardNumber,
      cardWidth,
      cardHeight,
      icons: {
        mdiHelp
      }
    }
  }

};
</script>

<style type='text/css' scoped>
i.frontFlipBtn,
i.backFlipBtn {
    position:absolute;
    right: 20px;
    top: 20px;
    color:#FFFFFF;
}
i.backFlipBtn {
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -o-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
}
.flip-container {
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -o-perspective: 1000;
  perspective: 1000;
}
.flip-container {
  min-height: 120px;
}
.flipper {
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  position: relative;
}
.permanent .v-card {
  border: 2px solid #56CA00;
}
.front,
.back {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  -moz-transition: 0.6s;
  -moz-transform-style: preserve-3d;
  -o-transition: 0.6s;
  -o-transform-style: preserve-3d;
  -ms-transition: 0.6s;
  -ms-transform-style: preserve-3d;
  transition: 0.6s;
  transform-style: preserve-3d;
  top: 0;
  left: 0;
  width: 100%;
}
.back {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  position: absolute;
}
.flip-container.flipped .back {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.flip-container.flipped .front {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.front {
  z-index: 2;
}
</style>
